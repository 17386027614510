import React, { useEffect, useState } from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getSrc } from "gatsby-plugin-image"
import parse from "html-react-parser"

// import Bio from "../components/bio"
import Layout from "../components/layout"
import MetaTags from '../components/Metatags';
import Share from '../components/share';
import PrevNext from '../components/prevnext';
import Container from "../components/container"
import GetStarted from '../components/blog-bottom-cta'
import kebabCase from "lodash/kebabCase"

import authors from '../utils/authors'

import Banner from "../images/banner-03.png"
import BlogImg2 from "../images/blog-cta-target.png";
import SubscribeBox1 from '../components/SubscribeBox1';
import popularArr from '../constants/popularPosts'
import TopBanner from './../components/topBannerOffer'
import Metatags from "../components/Metatags"

const BlogPostTemplate = ({ pageContext, data, location }) => {
  const { previous, next, post } = data;
  const featuredImage = {
    fluid: post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: post.featuredImage?.node?.alt || ``,
  }

  const uri = data.post.uri

// console.log(data.post)

  const url = data.site.siteMetadata.siteUrl;
  const sitename = data.site.siteMetadata.title;
  const metaKeywords = data.post?.seo?.metaKeywords;
  const metaTitle = data.post?.seo?.metaTitle;
  const metaDescription = data.post?.seo?.metaDescription;
  const authorName = post.author?.node?.name;
  const authorDescription = post.author?.node?.description;
  const authorImg = post.author?.node?.avatar?.url;
  // const authorBioInfo = authors.find(x => x.name === authorName) || 'Guest User';
  const thumbnail =  getSrc(data.post.featuredImage);
  const authorBio = authors.find(x => x.name === post.author?.node?.name) || 'Guest User';

  const [activeClass, setactiveClass] = useState('normal');
  useEffect(() => {
    window.addEventListener('scroll', () => {
      let active = '';
      if (window.scrollY > 2000) {
        active = 'top';
      }
      if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 800)) {
        active = 'normal';
      }
      if (window.scrollY === 0) {
        active = 'normal';
      }
      setactiveClass(active)
    });
  });

  return (
    <Layout className="blog-page">
      <Metatags
        title={metaTitle}
        description={metaDescription}
        thumbnail={url + thumbnail}
        url={url}
        uri = {uri}
        pathname={location.pathname}
        isBlogPost={true}
        keywords={metaKeywords}
        author={authorImg}
        sitename={sitename}
        datePublished={data.post.date}
        datemodified={data.post.modified}
        canonical={data.post.uri}
      />
      <TopBanner />
      <Container>
        <div className="row">
          <div className="col-md-9">
            <div className="single-blog-post">
              <div className="header">
                {/*<div className="image-section">*/}
                {/*{image && <Img fluid={image.childImageSharp.fluid}/>}*/}
                {/*</div>*/}
                <h1 itemProp="headline">{parse(post.title)}</h1>

                <p>
                  Posted
                    {post.author &&
                    <span className="author-name">
                      &nbsp;by <strong>{authorName == "Guest User" ? 'Guest User' : ` ${authorName}`}</strong>
                    </span>
                  }
                  {post.date &&
                    <span>
                      &nbsp;on&nbsp; <strong>{post.date}</strong>
                    </span>
                  }
                  {post.categories &&
                    <span>
                      &nbsp; in <strong>
                        {
                          post.categories.nodes.length > 0 &&
                          post.categories.nodes.map((cat, index) => {
                            return (
                              <>
                                <Link className="category-name" to={`/category/${kebabCase(cat.slug)}/`}> {cat.name}</Link>
                                {(post.categories.nodes.length > 1 && (index < post.categories.nodes.length - 1)) ? ',' : ''}
                              </>
                            );
                          })
                        }
                      </strong>
                    </span>
                  }
                  {/* &nbsp; | &nbsp;
                  <span className="reading-time">
                    {data.post.fields.readingTime.text}
                  </span> */}
                </p>
                <hr />

                {featuredImage?.fluid && (
                  <div className="image-section">
                    <GatsbyImage
                        image={featuredImage.fluid}
                        alt={featuredImage.alt}
                      />
                  </div>
                )}

              </div>
              {!!post.content && (
                <div className="content">
                  {parse(post.content)}
                </div>
              )}
              <hr />
              <div className="banner-img">
                <a href="https://app.optinly.com" rel="noopener" target="_blank">
                  <img src={Banner} alt="More about Optinly" />
                </a>
              </div>
              {authorName == "Guest User" ? '' :
                <div className="author-bio-section">
                  <h5 style={{ textDecoration: 'underline' }} className="mt-0 mb-3 text-center"><strong>Author Bio</strong></h5>
                  <div className="row justify-content-center align-items-center">
                    <div className="col-md-2">
                      {(authorBio.imageUrl == null || authorBio.imageUrl == 'undefined') ?
                        <><img className="img img-responsive img-author rounded-circle" src={authorImg} alt={authorName} />&nbsp;</>
                        :
                        <><img className="img img-responsive img-author rounded-circle" src={authorBio.imageUrl} alt={authorName} />&nbsp;</>
                      }
                    </div>
                    <div className="col-md-9">
                      <h5 className="mt-0 mb-2"><strong>{authorName}</strong></h5>
                      <div>
                        {authorDescription}
                      </div>
                    </div>
                  </div>
                </div>
              }
              <hr />

              <div className="footer">
                <Share title={post.title} url={url} pathname={location.pathname} />

                <PrevNext prev={previous && previous} next={next && next} />
                <div className="text-right">
                  <hr />
                  <Link to="/blog" className="btn btn-link">Go Back</Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="blog-sidebar">
              {/* <div className="widget-box">
                <h4 className="widget-title">Featured tags</h4>
                <div className="tags-list">
                  {
                    data.tagsGroup.nodes.map((tag, index) => {
                      if (tag.count > 0) {
                        return (
                          <Link className="tag-item" to={`/tags/${tag.slug}`} key={tag.slug}>{tag.name}</Link>
                        )
                      }
                      return;
                    })
                  }
                </div>
              </div> */}
              <SubscribeBox1 />
              <div className="widget-box">
                <h4 className="widget-title">Categories</h4>
                <div className="category-list">
                  {
                    data.categoryGroup.nodes.map((category, index) => {
                      if (category.count > 0) {
                        return (
                          <Link className="category-item" to={category.uri} key={category.slug}>{category.name}</Link>
                        )
                      }
                      return;
                    })
                  }
                </div>
              </div>
              <div className={`widget-box ${activeClass}`}>
                {/* <h4 className="widget-title">CTA Box</h4> */}
                <div className="cta-box">
                  <img src={BlogImg2} className="widget-image" alt="Optinly - CTA" />
                  <h4>Engage visitors, capture leads &amp; monetize website traffic!</h4>
                  <a href="https://app.optinly.com/" target="_blank" className="mt-3 btn btn-primary">Try Optinly Free!</a>
                  <p className="mt-3 mb-3 text-muted"><small>*No Credit Card Required <br /> <strong>*Free Forever</strong></small></p>
                </div>
              </div>
              <div className="widget-box popular-blog-widget">
                <h4 className="widget-title">Popular posts</h4>
                {popularArr.map((post, index) => {
                  return (
                    <div className="popular-blog-post" key={post.title} style={{ flexDirection: 'column', alignItems: 'flex-start', textAlign: "center" }}>
                      <div className="image-section" style={{ margin: "auto" }}>
                        <Link to={post.slug}>
                          <img src={post.imgSrc}
                            alt={post.title} />
                        </Link>
                      </div>
                      <div className="content-section">
                        <h4>
                          <Link to={`${post.slug}/`}>{post.title}</Link>
                        </h4>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </Container>
      <GetStarted />
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    # selecting the current post by id
    post: wpPost(id: { eq: $id }) {
      uri
      id
      excerpt
      content
      title
      date(formatString: "MMMM DD, YYYY")
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      author {
        node {
          avatar {
            url
          }
          name
          description
        }
      }
      categories {
        nodes {
          name
          slug
          count
        }
      }
      seo {
        metaTitle
        metaKeywords
        metaDescription
      }
    }
    # this gets us the previous post by id (if it exists)
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }
    # this gets us the next post by id (if it exists)
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }
    site {
      siteMetadata {
          siteUrl
          description
          title
        }
    }
    tagsGroup: allWpTag(limit: 20) {
      nodes {
          name
          slug
          uri
          count
      }
      totalCount
    }
    categoryGroup: allWpCategory(limit: 20) {
        nodes {
            name
            slug
            uri
            count
        }
        totalCount
    }
  }
`