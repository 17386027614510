import React from 'react'
import { Link } from 'gatsby'
import $ from 'jquery'
import Logo from '../images/Optinly.svg'

class topBannerOffer extends React.Component {
    componentDidMount() {
        $(window).scroll(function() {
          if ($(window).scrollTop() > 300) {
            $('.topbanner-container').addClass('fixed-top')
          } else {
            $('.topbanner-container').removeClass('fixed-top')
          }
          if (($(document).height() - $(window).height() - $(window).scrollTop() < 700)) {
            $('.topbanner-container').removeClass('fixed-top');
          }
        })
    }
    render() {
        return (
            <div className="topbanner-container">
                <div className="container-fluid">
                    <div>
                        <Link className="navbar-brand" to="/">
                            <img src={Logo} alt="Optinly" /> Optinly
                        </Link>
                    </div>
                    <div className="sale-offer-text">
                    Join 3000+ of our customers who have doubled their sales with the potential of Optinly.
                    </div>
                    <div className="ms-auto">
                        <a className="btn btn-primary me-3" href="https://app.optinly.com/" target="_blank" rel="noopener"> Get Started </a> 
                        <Link className="btn btn-light" to="/features" target="_blank" rel="noopener"> See All Features </Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default topBannerOffer
