import React from 'react';
import { FaAngleDoubleRight, FaAngleDoubleLeft } from "react-icons/fa";

const PrevNext = (props) => {

    const { prev, next } = props;

    return (
        <ul className="pagination-links">
            {prev && <li className="pull-left">
                <a href={`/blog${prev.uri}`} className="pagination-link-box">
                    <FaAngleDoubleLeft />
                    {prev.title}
                     {" "}
                </a></li>}
            {next && <li className="pull-right">
                <a href={`/blog${next.uri}`} className="pagination-link-box">
                    {" "} 
                        {next.title}
                    <FaAngleDoubleRight />
                </a>
            </li>}
        </ul>
    )
}

export default PrevNext;